@mixin animate($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin animation($animation) {
  -webkit-animation: #{$animation};
  -moz-animation: #{$animation};
  -ms-animation: #{$animation};
  animation: #{$animation};
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

/* ==========================================================================
   Components Atoms - Animations
   ========================================================================== */

/* Mixin - Animation Opacity
   ========================================================================== */

@mixin animation-opacity {
  @include single-transition(opacity, $default-transition-duration, $default-transition-function);
}

/* Mixin - Animation Min-height
   ========================================================================== */

@mixin animation-min-height {
  @include single-transition(
    min-height,
    $default-transition-duration,
    $default-transition-function
  );
}

/* Mixin - Animation Background-color
   ========================================================================== */

@mixin animation-background-color {
  @include single-transition(
    background-color,
    $default-transition-duration,
    $default-transition-function
  );
}

/* Mixin - Animation Text Color
   ========================================================================== */

@mixin animation-text-color {
  @include single-transition(color, $default-transition-duration, $default-transition-function);
}

/* Mixin - Animation All
   ========================================================================== */

@mixin animation-all {
  @include single-transition(
    $default-transition-property,
    $default-transition-duration,
    $default-transition-function
  );
}

@mixin animation-translate {
  @include single-transition(transform, $default-transition-duration, $default-transition-function);
}
