/* ==========================================================================
   Global Variables - Type-sizes
   ========================================================================== */

$typesize--11px: 11px;
$typesize--12px: 12px;
$typesize--13px: 13px;
$typesize--14px: 14px;
$typesize--15px: 15px;
$typesize--16px: 16px;
$typesize--18px: 18px;
$typesize--19px: 19px;
$typesize--20px: 20px;
$typesize--22px: 22px;
$typesize--24px: 24px;
$typesize--26px: 26px;
$typesize--27px: 27px;
$typesize--28px: 28px;
$typesize--30px: 30px;
$typesize--35px: 35px;
$typesize--36px: 36px;
$typesize--48px: 48px;
$typesize--52px: 52px;
$typesize--60px: 60px;
$typesize--80px: 80px;
$typesize--100px: 100px;

$lineheight--13-11: 13px / $typesize--11px;
$lineheight--14-12: 14px / $typesize--12px;
$lineheight--18-12: 18px / $typesize--12px;
$lineheight--18-14: 18px / $typesize--14px;
$lineheight--18-15: 18px / $typesize--15px;
$lineheight--18-18: 18px / $typesize--18px;
$lineheight--20-12: 20px / $typesize--12px;
$lineheight--20-18: 20px / $typesize--18px;
$lineheight--30-24: 30px / $typesize--24px;
$lineheight--30-28: 30px / $typesize--28px;
$lineheight--30-30: 30px / $typesize--30px;
$lineheight--44-36: 44px / $typesize--36px;
$lineheight--45-35: 44px / $typesize--35px;
$lineheight--42-48: 42px / $typesize--48px;
$lineheight--44-52: 44px / $typesize--52px;
$lineheight--48-60: 48px / $typesize--60px;
$lineheight--54-80: 54px / $typesize--80px;
$lineheight--86-100: 86px / $typesize--100px;

// Font size and line-height
$root-typesize: 12px;
$base-font-size: $root-typesize;
$base-line-px: 18px;
$base-line-em: em($base-line-px);
$base-line-height: unitless-convert($base-line-em);

//==========================================================================
// Base - Typography
//==========================================================================

/* ==========================================================================
   Global Variables - Fonts
   ========================================================================== */

/*
 * Simply list your font information in the map below and the @each will take
 * care of the rest.
 * Used to import font faces in base/_typography.scss and define font families.
 *
 * Note: Try to use weight variants where applicable.
 * For example, tstar_mono_round is setup to use tstar_mono_roundbold for the
 * bold variant. Similarly, TradeGothicLTStd can be setup to use
 * TradeGothicLTStdBold for the bold variant.
 *
 * NOTE: see array below for newer versions only using woff and woff2
 */

$fonts: (
  tstar_mono_roundregular: (
    font-name-mac: 'tstar_mono_roundregular',
    font-folder-mac: 'Tstar',
    font-filename-mac: 'TstarMonoRound-Regular-Webfont',
    font-weight-mac: 'normal',
    font-style-mac: 'normal'
  ),
  tstar_mono_roundbold: (
    font-name-mac: 'tstar_mono_roundbol',
    font-folder-mac: 'Tstar',
    font-filename-mac: 'TstarMonoRound-Bold-Webfont',
    font-weight-mac: 'normal',
    font-style-mac: 'normal'
  ),
  roboto-condensed-regular: (
    font-name-mac: 'roboto-condensed-regular',
    font-folder-mac: 'Roboto',
    font-filename-mac: 'RobotoCondensed-Regular',
    font-weight-mac: 'normal',
    font-style-mac: 'normal'
  ),
  roboto-condensed-bold: (
    font-name-mac: 'roboto-condensed-bold',
    font-folder-mac: 'Roboto',
    font-filename-mac: 'RobotoCondensed-Bold',
    font-weight-mac: 'bold',
    font-style-mac: 'normal'
  ),
  roboto-mono_medium: (
    font-name-mac: 'roboto-mono_medium',
    font-folder-mac: 'Roboto',
    font-filename-mac: 'RobotoMono-Medium',
    font-weight-mac: 'normal',
    font-style-mac: 'normal'
  ),
  roboto-mono_regular: (
    font-name-mac: 'roboto-mono_regular',
    font-folder-mac: 'Roboto',
    font-filename-mac: 'RobotoMono-Regular',
    font-weight-mac: 'bold',
    font-style-mac: 'normal'
  ),
  roboto-mono_bold: (
    font-name-mac: 'roboto-mono_bold',
    font-folder-mac: 'Roboto',
    font-filename-mac: 'RobotoMono-Bold',
    font-weight-mac: 'bold',
    font-style-mac: 'normal'
  )
);

// This is updated for 2018 as we only need to support woff and woff2 formats
// Added more for the 2021 style refresh
$fonts_new: (
  ano-black_regular: (
    font-name-mac: 'ano-black_regular',
    font-folder-mac: 'Ano',
    font-filename-mac: 'AnoBlack-Regular',
    font-weight-mac: 'normal',
    font-style-mac: 'normal'
  ),
  ano-bold_regular: (
    font-name-mac: 'ano-bold_regular',
    font-folder-mac: 'Ano',
    font-filename-mac: 'AnoBold-Regular',
    font-weight-mac: 'bold',
    font-style-mac: 'normal'
  ),
  ano_regular: (
    font-name-mac: 'ano_regular',
    font-folder-mac: 'Ano',
    font-filename-mac: 'AnoRegular-Regular',
    font-weight-mac: 'normal',
    font-style-mac: 'normal'
  ),
  noto_regular: (
    font-name-mac: 'noto_regular',
    font-folder-mac: 'Noto',
    font-filename-mac: 'NotoSans-Regular',
    font-weight-mac: 'normal',
    font-style-mac: 'normal'
  ),
  noto-black: (
    font-name-mac: 'noto-black',
    font-folder-mac: 'Noto',
    font-filename-mac: 'NotoSans-Black',
    font-weight-mac: 'normal',
    font-style-mac: 'normal'
  ),
  assistant-bold: (
    font-name-mac: 'assistant-bold',
    font-folder-mac: 'Assistant',
    font-filename-mac: 'Assistant-Bold',
    font-weight-mac: 'bold',
    font-style-mac: 'normal'
  ),
  assistant-regular: (
    font-name-mac: 'assistant-regular',
    font-folder-mac: 'Assistant',
    font-filename-mac: 'Assistant-Regular',
    font-weight-mac: 'normal',
    font-style-mac: 'normal'
  ),
  assistant-light: (
    font-name-mac: 'assistant-light',
    font-folder-mac: 'Assistant',
    font-filename-mac: 'Assistant-VariableFont_wght',
    font-weight-mac: 'normal',
    font-style-mac: 'normal'
  ),
  assistant-extra-bold: (
    font-name-mac: 'assistant-extra-bold',
    font-folder-mac: 'Assistant',
    font-filename-mac: 'Assistant-ExtraBold',
    font-weight-mac: 'normal',
    font-style-mac: 'normal'
  )
);

/* Font names
   ========================================================================== */
/*
 * Assign font names from global/variables/_fonts.scss $fonts map
 * to actual usable font family strings.
 */

$ano-black_regular-font: font-name-new('ano-black_regular'), Helvetica, Arial, sans-serif !default;
$ano-bold_regular-font: font-name-new('ano-bold_regular'), Helvetica, Arial, sans-serif !default;
$ano_regular-font: font-name-new('ano_regular'), Helvetica, Arial, sans-serif !default;
$helvetica-font: Helvetica, Arial, sans-serif !default;
$tstar-font: font-name('tstar_mono_roundregular'), Helvetica, Arial, sans-serif !default;
$tstar-bold-font: font-name('tstar_mono_roundbold'), Helvetica, Arial, sans-serif !default;
$times-font: TimesNewRoman, 'Times New Roman', Times, serif !default;
$roboto-condensed-bold-font: font-name('roboto-condensed-bold'), Helvetica, Arial, sans-serif !default;
$roboto-condensed-regular-font: font-name('roboto-condensed-regular'), Helvetica, Arial, sans-serif !default;
$roboto-mono_regular-font: font-name('roboto-mono_regular'), Helvetica, Arial, sans-serif !default;
$roboto-mono_medium-font: font-name('roboto-mono_medium'), Helvetica, Arial, sans-serif !default;
$roboto-mono_bold-font: font-name('roboto-mono_bold'), Helvetica, Arial, sans-serif !default;

// CR21 - specific fonts by usecase, allows overrides per local
$product-display-name: font-name-new('ano-bold_regular'), Helvetica, Arial, sans-serif !default;
$product-shade-name: font-name-new('ano-bold_regular'), Helvetica, Arial, sans-serif !default;

//Tstar font replaces the roboto mono font when "$tstar_font_update == true"

$tstar_font_update: false !default;

@if $tstar_font_update == true {
  $roboto-mono_regular-font: $tstar-font;
  $roboto-mono_bold-font: $tstar-bold-font;
}


$gnav_update: false !default;
$fonts_update: false !default;

// Standalone Loyalty Status Panel.
$lsp_use_roboto_condenset_bold: false !default;
$lsp_use_roboto_condenset_regular: false !default;
$lsp_use_custom_font_size: false !default;
