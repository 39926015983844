///
/// @file standalone/shared/_animations.scss
///

// standalone shared animations
@mixin shimmer($duration: 1s, $easing: linear) {
  animation: shimmer $duration 60 $easing;
  backface-visibility: hidden;
  background: $color-light-warm-grey
    linear-gradient(
      -45deg,
      $color-light-warm-grey 0%,
      $color-light-warm-grey 40%,
      $color-grey-fill 50%,
      $color-light-warm-grey 60%,
      $color-light-warm-grey 100%
    );
  background-size: 250% 250%;
  perspective: 1000px;
  transform: rotateZ(360deg);
  will-change: transform;
  [dir='rtl'] & {
    animation: shimmer-rtl $duration 60 $easing;
    backface-visibility: hidden;
    background: $color-grey-fill
      linear-gradient(
        45deg,
        $color-light-warm-grey 0%,
        $color-light-warm-grey 40%,
        $color-grey-fill 50%,
        $color-light-warm-grey 60%,
        $color-light-warm-grey 100%
      );
    background-size: 250% 250%;
    perspective: 1000px;
    transform: rotateZ(360deg);
    will-change: transform;
  }
}

@mixin fade-in($duration: 1s, $easing: linear) {
  animation: fade-in $duration $easing forwards;
}

/// standalone shared keyframes animation
@keyframes shimmer {
  0% {
    background-position: bottom right;
  }
  100% {
    background-position: top left;
  }
}

@keyframes shimmer-rtl {
  0% {
    background-position: bottom left;
  }
  100% {
    background-position: top right;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
