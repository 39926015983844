@import '../../../../scss/theme-bootstrap';

$grid-row-gutter: 25px;
@if $cr21 == true {
  $grid-row-gutter: 20px;
}

.grid-row-formatter {
  position: relative;
  width: 100%;
  &__wrapper {
    width: initial;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
  }
  &__header {
    width: 100%;
    > div:first-of-type {
      padding-top: 40px;
      @include breakpoint($bp--large-up) {
        padding-top: 60px;
      }
    }
    > div:last-of-type {
      padding-bottom: 40px;
      @include breakpoint($bp--large-up) {
        padding-bottom: 60px;
      }
    }
    > div:empty {
      display: none;
    }
    &-content:empty {
      display: none;
    }
  }
  &__grid {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    @include breakpoint($bp--large-up) {
      flex-direction: row;
    }
    &-item {
      width: 100%;
    }
    &-item:not(:last-of-type):not(.grid-row-formatter__grid-item--zero-margin) {
      margin-bottom: $grid-row-gutter;
      @include breakpoint($bp--large-up) {
        margin-bottom: 0;
        margin-#{$rdirection}: $grid-row-gutter;
      }
    }
  }
  &--standard-margin {
    &:not([class*=' padding-']) {
      @include breakpoint($bp--medium-up) {
        padding: 0 $grid-row-gutter;
      }
      &:not([class*=' mobile-padding-']) {
        padding: 0 $grid-row-gutter;
      }
    }
  }
  .grid-row-formatter__header-media {
    .mantle-media-asset img,
    .mantle-media-asset picture,
    .mantle-media-asset video {
      width: auto;
      height: auto;
    }
  }
  h1 {
    line-height: 0.8;
  }
  .content-block__line {
    // compensate for the WYSIWYG adding extra elements
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      padding: 0;
    }
  }
}
