// Body Text
// ===
$body-font-family: $roboto-mono_regular-font !default;
$body-font-family--bold: $roboto-mono_bold-font !default;
$body-font-size: $typesize--12px !default;
$input-font-size: $typesize--16px;
$body-lineheight: $lineheight--18-12 !default;

// Body Text - New fonts
$body-new-font-family: $helvetica-font !default;
$body-new-font-family--bold: $ano-bold_regular-font !default;
$body-new-font-size: $typesize--15px !default;
$input-new-font-size: $typesize--15px;
$body-new-lineheight: $lineheight--30-24 !default;

@mixin body-mono($bold: false) {
  @include fontsize-rem($body-font-size);
  @if $fonts_update == true {
    font-family: $body-new-font-family;
    font-size: $body-new-font-size;
    line-height: $body-new-lineheight;
  } @else {
    font-family: $body-font-family;
    line-height: $body-lineheight;
  }
  @if $bold {
    @if $fonts_update == true {
      font-family: $body-new-font-family--bold;
    } @else {
      font-family: $body-font-family--bold;
    }
  }
}

%body-mono {
  @include body-mono();
}

%body-mono--bold {
  @include body-mono($bold: true);
}

// Headings
// ===
$headline-font-family: $ano-bold_regular-font !default;

$h1-size: $typesize--60px !default;
$h2-size: $typesize--48px !default;
$h3-size: $typesize--36px !default;
$h4-size: $typesize--30px !default;
$h5-size: $typesize--24px !default;
$h6-size: $typesize--18px !default;

$h1-lineheight: $lineheight--48-60 !default;
$h2-lineheight: $lineheight--42-48 !default;
$h3-lineheight: $lineheight--44-36 !default;
$h4-lineheight: $lineheight--30-30 !default;
$h5-lineheight: $lineheight--30-24 !default;
$h6-lineheight: $lineheight--20-18 !default;

// New font for headings
$headline-ano-bold-font: $ano-bold_regular-font !default;
$headline-ano-black-font: $ano-black_regular-font !default;
$headline-helvetica-font: $helvetica-font !default;

$h1-pc-size: $typesize--80px !default;
$h1-mob-size: $typesize--52px !default;
$h2-pc-size: $typesize--60px !default;
$h2-mob-size: $typesize--30px !default;
$h3-pc-size: $typesize--35px !default;
$h3-mob-size: $typesize--28px !default;
$h4-new-size: $typesize--18px !default;
$h5-pc-size: $typesize--14px !default;
$h5-mob-size: $typesize--15px !default;
$h6-new-size: $typesize--15px !default;

$h1-pc-lineheight: $lineheight--54-80 !default;
$h1-mob-lineheight: $lineheight--44-52 !default;
$h2-pc-lineheight: $lineheight--48-60 !default;
$h2-mob-lineheight: $lineheight--30-30 !default;
$h3-pc-lineheight: $lineheight--45-35 !default;
$h3-mob-lineheight: $lineheight--30-28 !default;
$h4-new-lineheight: $lineheight--18-18 !default;
$h5-pc-lineheight: $lineheight--18-14 !default;
$h5-mob-lineheight: $lineheight--18-15 !default;
$h6-new-lineheight: $lineheight--18-15 !default;

@mixin heading-base() {
  font-family: $headline-font-family;
  font-weight: normal;
  text-transform: uppercase;
}

@mixin heading-ano-bold() {
  font-family: $headline-ano-bold-font;
  font-weight: bold;
  text-transform: uppercase;
}

@mixin heading-ano-black() {
  font-family: $headline-ano-black-font;
  font-weight: normal;
  text-transform: uppercase;
}

@mixin heading-helvetica() {
  font-family: $headline-helvetica-font;
  font-weight: bold;
  text-transform: uppercase;
}

@mixin h1() {
  @include heading-base();
  @include fontsize-rem($h1-size);
  @if $fonts_update == true {
    @include heading-ano-bold();
    font-size: $h1-mob-size;
    letter-spacing: -2px;
    line-height: $h1-mob-lineheight;
    margin-bottom: base-line-calc(0.5, $h1-mob-size);
    margin-top: base-line-calc(0.5, $h1-mob-size);
    @include breakpoint($bp--large-up) {
      font-size: $h1-pc-size;
      letter-spacing: -2.25px;
      line-height: $h1-pc-lineheight;
      margin-bottom: base-line-calc(0.5, $h1-pc-size);
      margin-top: base-line-calc(0.5, $h1-pc-size);
    }
  } @else {
    line-height: $h1-lineheight;
    margin-bottom: base-line-calc(0.5, $h1-size);
    margin-top: base-line-calc(0.5, $h1-size);
  }
  @if $cr21 == true {
    @include title-01();
  }
}

@mixin h2() {
  @include heading-base();
  @include fontsize-rem($h2-size);
  @if $fonts_update == true {
    @include heading-ano-bold();
    font-size: $h2-mob-size;
    letter-spacing: -1px;
    line-height: $h2-mob-lineheight;
    margin-bottom: base-line-calc(0.5, $h2-mob-size);
    margin-top: base-line-calc(0.5, $h2-mob-size);
    @include breakpoint($bp--large-up) {
      font-size: $h2-pc-size;
      letter-spacing: -3px;
      line-height: $h2-pc-lineheight;
      margin-bottom: base-line-calc(0.5, $h2-pc-size);
      margin-top: base-line-calc(0.5, $h2-pc-size);
    }
  } @else {
    line-height: $h2-lineheight;
    margin-bottom: base-line-calc(0.5, $h2-size);
    margin-top: base-line-calc(0.5, $h2-size);
  }
  @if $cr21 == true {
    @include title-02();
  }
}

@mixin h3() {
  @include heading-base();
  @include fontsize-rem($h3-size);
  @if $fonts_update == true {
    @include heading-ano-bold();
    font-size: $h3-mob-size;
    letter-spacing: -2px;
    line-height: $h3-mob-lineheight;
    margin-bottom: base-line-calc(0.5, $h3-mob-size);
    margin-top: base-line-calc(0.5, $h3-mob-size);
    @include breakpoint($bp--large-up) {
      font-size: $h3-pc-size;
      letter-spacing: -1px;
      line-height: $h3-pc-lineheight;
      margin-bottom: base-line-calc(0.5, $h3-pc-size);
      margin-top: base-line-calc(0.5, $h3-pc-size);
    }
  } @else {
    line-height: $h3-lineheight;
    margin-bottom: base-line-calc(0.5, $h3-size);
    margin-top: base-line-calc(0.5, $h3-size);
  }
  @if $cr21 == true {
    @include title-03();
  }
}

@mixin h4() {
  @include heading-base();
  @include fontsize-rem($h4-size);
  @if $fonts_update == true {
    @include heading-ano-bold();
    font-size: $h4-new-size;
    letter-spacing: -1px;
    line-height: $h4-new-lineheight;
    margin-bottom: base-line-calc(0.25, $h4-new-size);
    margin-top: base-line-calc(0.25, $h4-new-size);
  } @else {
    line-height: $h4-lineheight;
    margin-bottom: base-line-calc(0.25, $h4-size);
    margin-top: base-line-calc(0.25, $h4-size);
  }
}

@mixin h5() {
  @include heading-base();
  @include fontsize-rem($h5-size);
  @if $fonts_update == true {
    @include heading-helvetica();
    font-size: $h5-mob-size;
    letter-spacing: 0;
    line-height: $h5-mob-lineheight;
    margin-bottom: base-line-calc(0.25, $h5-mob-size);
    margin-top: base-line-calc(0.25, $h5-mob-size);
    @include breakpoint($bp--large-up) {
      font-size: $h5-pc-size;
      line-height: $h5-pc-lineheight;
      margin-bottom: base-line-calc(0.5, $h5-pc-size);
      margin-top: base-line-calc(0.5, $h5-pc-size);
    }
  } @else {
    line-height: $h5-lineheight;
    margin-bottom: base-line-calc(0.25, $h5-size);
    margin-top: base-line-calc(0.25, $h5-size);
  }
}

@mixin h6() {
  @include heading-base();
  @include fontsize-rem($h6-size);
  @if $fonts_update == true {
    @include heading-ano-black();
    font-size: $h6-new-size;
    letter-spacing: 0;
    line-height: $h6-new-lineheight;
    margin-bottom: base-line-calc(0.25, $h6-new-size);
    margin-top: base-line-calc(0.25, $h6-new-size);
  } @else {
    line-height: $h6-lineheight;
    margin-bottom: base-line-calc(0.25, $h6-size);
    margin-top: base-line-calc(0.25, $h6-size);
  }
}

// h7
@mixin h7 {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.2em;
  position: relative;
  text-align: center;
  display: inline-block;
  padding: 0 33px;
  margin: 0 auto 30px;
  @if $fonts_update == true {
    @include heading-helvetica();
  } @else {
    font-family: $ano-bold_regular-font;
    font-weight: normal;
    text-transform: uppercase;
  }
  &:before {
    content: '';
    background: $color--black;
    width: 100%;
    height: 1px;
    display: inline-block;
    position: absolute;
    top: 43%;
    #{$ldirection}: 0;
  }
  .inner,
  &__inner {
    @include swap_direction(padding, 0 7px 0 9px);
    background: $color--white;
    display: inline-block;
    position: relative;
    background-clip: padding-box;
  }
}

$heading-typesize: $typesize--36px;
$heading-lineheight: $lineheight--44-36;

$mono-preheading-typesize: $typesize--14px;
$mono-preheading-lineheight: $lineheight--18-14;
@mixin preheading-mono {
  font-family: $body-font-family;
  @include fontsize-rem($mono-preheading-typesize);
  line-height: $mono-preheading-lineheight;
  text-transform: uppercase;
}

$main-heading-typesize: $typesize--100px;
$main-heading-lineheight: $lineheight--86-100;
@mixin heading-trade-large {
  font-family: $headline-font-family;
  @include fontsize-rem($main-heading-typesize);
  line-height: $main-heading-lineheight;
  text-transform: uppercase;
}

$second-heading-typesize: $typesize--48px;
$second-heading-lineheight: $lineheight--42-48;
@mixin heading-trade-cond-large {
  font-family: $headline-font-family;
  @include fontsize-rem($second-heading-typesize);
  line-height: $second-heading-lineheight;
  text-transform: uppercase;
}

$subnav-typesize: $typesize--18px;
$subnav-lineheight: $lineheight--18-18;
@mixin subnav-trade-cond {
  font-family: $headline-font-family;
  @include fontsize-rem($subnav-typesize);
  line-height: $subnav-lineheight;
  @if $fonts_update == true {
    font-family: $headline-ano-bold-font;
    font-size: 18px;
  }
}

@mixin tweet-trade-cond {
  @include subnav-trade-cond;
}

$global-footer-typesize: $typesize--18px;
$global-footer-lineheight: $lineheight--20-18;
@mixin global-footer {
  font-family: $headline-font-family;
  @include fontsize-rem($global-footer-typesize);
  line-height: $global-footer-lineheight;
}

@mixin artist-content-headings {
  @include global-footer;
}

$mpp-heading-typesize: $typesize--24px;
$mpp-heading-lineheight: $lineheight--30-24;
@mixin heading--small {
  font-family: $headline-font-family;
  @include fontsize-rem($mpp-heading-typesize);
  line-height: $mpp-heading-lineheight;
  text-transform: uppercase;
  @if $fonts_update == true {
    font-family: $headline-ano-bold-font;
    font-size: 15px;
    letter-spacing: -1px;
    @include breakpoint($bp--large-up) {
      font-size: 18px;
    }
  }
}

@mixin artist-video-heading {
  @include heading--small;
}

$media-captions-reverse-typesize: $typesize--11px;
$media-captions-reverse-lineheight: $lineheight--13-11;
@mixin media-caption {
  font-family: $body-font-family;
  @include fontsize-rem($media-captions-reverse-typesize);
  line-height: $media-captions-reverse-lineheight;
}

$global-heading-mono-typesize: $typesize--12px;
$global-heading-mono-lineheight: $lineheight--14-12;

@mixin heading-mono {
  @include fontsize-rem($global-heading-mono-typesize);
  line-height: $global-heading-mono-lineheight;
  text-transform: uppercase;
  @if $fonts_update == true {
    font-family: $headline-helvetica-font;
    font-size: 12px;
  } @else {
    font-family: $body-font-family;
  }
}

$solo-section-title-typesize: $typesize--30px;
$solo-section-title-lineheight: $lineheight--30-30;
@mixin title-section {
  @if $fonts_update == true {
    font-family: $headline-ano-bold-font;
  } @else {
    font-family: $headline-font-family;
  }
  @include fontsize-rem($solo-section-title-typesize);
  line-height: $solo-section-title-lineheight;
  text-transform: uppercase;
}

@mixin subnav-active {
  @include title-section;
}

$large-section-title-typesize: $typesize--60px;
$large-section-title-lineheight: $lineheight--48-60;
@mixin title-section-large {
  font-family: $headline-font-family;
  @include fontsize-rem($large-section-title-typesize);
  line-height: $large-section-title-lineheight;
  text-transform: uppercase;
}

$pull-quote-typesize: $typesize--30px;
$pull-quote-lineheight: $lineheight--30-30;
@mixin pullquote {
  font-family: $headline-font-family;
  @include fontsize-rem($pull-quote-typesize);
  line-height: $pull-quote-lineheight;
}

@mixin emphasis-caps {
  @include subnav-trade-cond;
  text-transform: uppercase;
}

$eyebrow-typesize: $typesize--12px;
$eyebrow-lineheight: $lineheight--20-12;
@mixin eyebrow-text {
  font-family: $body-font-family;
  @include fontsize-rem($eyebrow-typesize);
  line-height: $eyebrow-lineheight;
  text-transform: uppercase;
}

@mixin reverse() {
  background-color: $color--black;
  @include reverse-text;
}

@mixin reverse-text {
  color: $color--white;
  a {
    @include set-link-colors(
      $color--white,
      $color--white,
      $color--white,
      $color--white,
      $color--white,
      false
    );
    @include single-transition($property: border-bottom-color);
    border-bottom-color: $color--gray--light;
    &:hover,
    &:active,
    &:focus {
      border-bottom-color: $color--white;
    }
  }
}

@mixin unset-reverse-text {
  color: $color--black;
  a {
    @include set-link-colors();
  }
}

// 2021 style refresh
// NEW typography mixins
// @TODO convert to REM (suzi does not like the current function for this)

// Title01
@mixin title-01 {
  font-family: $ano_regular-font;
  font-size: 36px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  line-height: get-line-height(36px, 40px);
  @include breakpoint($bp--large-up) {
    font-size: 54px;
    line-height: get-line-height(54px, 64px);
  }
}

// Title02
@mixin title-02 {
  font-family: $ano_regular-font;
  font-size: 28px;
  font-style: normal;
  font-weight: normal;
  line-height: get-line-height(28px, 32px);
  letter-spacing: 0;
  @include breakpoint($bp--large-up) {
    font-size: 36px;
    line-height: get-line-height(36px, 40px);
  }
}

// Title02 - Bold
@mixin title-02--bold {
  @include title-02;
  font-family: $ano-bold_regular-font;
}

// Title03
@mixin title-03 {
  font-family: $ano_regular-font;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: get-line-height(20px, 24px);
  letter-spacing: 0;
  @include breakpoint($bp--large-up) {
    font-size: 24px;
    line-height: get-line-height(24px, 28px);
  }
}

// Title03 - Bold
@mixin title-03--bold {
  font-family: $ano-bold_regular-font;
  font-size: 20px;
  font-weight: bold;
  line-height: get-line-height(20px, 24px);
  letter-spacing: 0;
  @include breakpoint($bp--large-up) {
    font-size: 24px;
    line-height: get-line-height(24px, 30px);
  }
}

// Subtitle - Regular
@mixin sub-title {
  font-family: $ano_regular-font;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  line-height: get-line-height(14px, 16px);
}
// Subtitle - Bold
@mixin sub-title--bold {
  font-family: $ano-bold_regular-font;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0;
  line-height: get-line-height(14px, 16px);
}
// Body - Regular
@mixin body-text {
  font-family: $ano_regular-font;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  line-height: get-line-height(16px, 24px);
}
// Body - Bold
@mixin body-text--bold {
  font-family: $ano-bold_regular-font;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0;
  line-height: get-line-height(16px, 24px);
}
// Small Text - Regular
// Used in CTAs and products
@mixin body-text--small {
  font-family: $ano_regular-font;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.30000001192092896px;
  line-height: get-line-height(12px, 14px);
}
// Small Text - Bold
@mixin body-text--small-bold {
  font-family: $ano-bold_regular-font;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.30000001192092896px;
  line-height: get-line-height(12px, 14px);
}

// Legal - Regular
@mixin legal-text {
  font-family: $ano_regular-font;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  line-height: get-line-height(14px, 18px);
}
// Legal - Bold
@mixin legal-text--bold {
  font-family: $ano-bold_regular-font;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0;
  line-height: get-line-height(14px, 18px);
}

// Responsive font sizes
// Apply vw unit for 768px - 1440px

.responsive-font-220px {
  font-family: $ano_regular-font;
  @include breakpoint($bp--large-up) {
    font-size: 15.277vw;
  }
  @include breakpoint($bp--xxxlarge-up) {
    font-size: 220px;
  }
  .mantle-custom-text {
    font-size: inherit;
  }
}
.responsive-font-200px {
  font-family: $ano_regular-font;
  @include breakpoint($bp--large-up) {
    font-size: 13.888vw;
  }
  @include breakpoint($bp--xxxlarge-up) {
    font-size: 200px;
  }
  .mantle-custom-text {
    font-size: inherit;
  }
}
.responsive-font-175px {
  font-family: $ano_regular-font;
  @include breakpoint($bp--large-up) {
    font-size: 12.1527vw;
  }
  @include breakpoint($bp--xxxlarge-up) {
    font-size: 175px;
  }
  .mantle-custom-text {
    font-size: inherit;
  }
}
.responsive-font-140px {
  font-family: $ano_regular-font;
  @include breakpoint($bp--large-up) {
    font-size: 9.7222vw;
  }
  @include breakpoint($bp--xxxlarge-up) {
    font-size: 140px;
  }
  .mantle-custom-text {
    font-size: inherit;
  }
}
.responsive-font-132px {
  font-family: $ano_regular-font;
  @include breakpoint($bp--large-up) {
    font-size: 9.1666vw;
  }
  @include breakpoint($bp--xxxlarge-up) {
    font-size: 132px;
  }
  .mantle-custom-text {
    font-size: inherit;
  }
}
.responsive-font-120px {
  font-family: $ano_regular-font;
  @include breakpoint($bp--large-up) {
    font-size: 8.2vw;
  }
  @include breakpoint($bp--xxxlarge-up) {
    font-size: 120px;
  }
  .mantle-custom-text {
    font-size: inherit;
  }
}
.responsive-font-80px {
  font-family: $ano_regular-font;
  @include breakpoint($bp--large-up) {
    font-size: 5.551vw;
  }
  @include breakpoint($bp--xxxlarge-up) {
    font-size: 80px;
  }
  .mantle-custom-text {
    font-size: inherit;
  }
}
.responsive-font-60px {
  font-family: $ano_regular-font;
  @include breakpoint($bp--large-up) {
    font-size: 4.166vw;
  }
  @include breakpoint($bp--xxxlarge-up) {
    font-size: 60px;
  }
  .mantle-custom-text {
    font-size: inherit;
  }
}
.responsive-font-54px {
  font-family: $ano_regular-font;
  @include breakpoint($bp--large-up) {
    font-size: 3.75vw;
  }
  @include breakpoint($bp--xxxlarge-up) {
    font-size: 54px;
  }
  .mantle-custom-text {
    font-size: inherit;
  }
}
.responsive-font-50px {
  font-family: $ano_regular-font;
  @include breakpoint($bp--large-up) {
    font-size: 3.472vw;
  }
  @include breakpoint($bp--xxxlarge-up) {
    font-size: 50px;
  }
  .mantle-custom-text {
    font-size: inherit;
  }
}
.responsive-font-48px {
  font-family: $ano_regular-font;
  @include breakpoint($bp--large-up) {
    font-size: 3.33vw;
  }
  @include breakpoint($bp--xxxlarge-up) {
    font-size: 48px;
  }
  .mantle-custom-text {
    font-size: inherit;
  }
}
.responsive-font-24px {
  font-family: $ano_regular-font;
  @include breakpoint($bp--large-up) {
    font-size: 1.666vw;
  }
  @include breakpoint($bp--xxxlarge-up) {
    font-size: 24px;
  }
  .mantle-custom-text {
    font-size: inherit;
  }
}
strong {
  font-size: inherit;
  font-family: inherit;
}
